<template>
  <div class="position-relative" v-click-outside="hideDropdown">
    <v-text-field
      v-model="value"
      :label="label"
      :disabled="disabled"
      outlined
      dense
      hide-details
      @focus="showDropdown"
      @keyup="getSuggestion"
      class="c-input-small"
      :placeholder="$t('labels.barcode_name')"
    ></v-text-field>
    <div class="position-absolute result-list" v-if="dropdown">
      <template v-if="suggestions.length > 0">
        <!-- Goods -->
        <div v-if="suggestions.length > 0" class="mb-3">
          <v-simple-table class="table-padding-2-no-top">
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th class="text-center error--text">
                    {{ $t("labels.combo") }}
                  </th>
                  <th class="text-center error--text">
                    {{ $t("labels.goods") }}
                  </th>
                  <th class="text-center" style="width: 75px"></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  class="result-item"
                  :class="{
                    'selected-item': selectedIds.includes(suggestion.id),
                  }"
                  v-for="suggestion in suggestions"
                  :key="`cb_${suggestion.id}`"
                >
                  <td class="text-center">
                    <div class="pl-1">
                      <span class="font-weight-medium" style="color: #b71c1c">
                        {{ suggestion.barcode }}
                      </span>
                      <br />
                      <span>{{ suggestion.name }}</span>
                    </div>
                  </td>
                  <td class="text-left">
                    <div
                      class="d-flex align-center"
                      v-for="(g, k) in suggestion.goods"
                      :key="`cg_${k}_${g.id}`"
                    >
                      <div style="width: 50px">
                        <ImageViewer
                          :url="
                            g.url_images ||
                            require('@/assets/common/no-image.jpg')
                          "
                          width="40px"
                          height="40px"
                        />
                      </div>
                      <div>
                        <div>
                          <span
                            class="font-weight-medium"
                            style="color: #b71c1c"
                          >
                            {{ g.customer_goods_barcode }}
                          </span>
                          - {{ $t("labels.sku") }}: {{ g.sku }}
                          <span style="color: #b71c1c">* {{ g.quantity }}</span>
                        </div>
                        <div>
                          {{ g.name }} - {{ g.description }} - {{ g.size }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="text-center">
                    <v-btn
                      v-if="!selectedIds.includes(suggestion.id)"
                      x-small
                      color="primary"
                      @click="selectItem(suggestion)"
                    >
                      {{ $t("labels.select") }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="pt-2" v-if="totalPage > 1">
            <v-pagination
              class="mini-pagination"
              v-model="page"
              :length="totalPage"
              :total-visible="6"
            ></v-pagination>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="result-item font-italic">
          <div>
            {{
              !value ? $t("labels.enter_barcode_sku_name_description_size") : ""
            }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";

export default {
  name: "SelectSuggestComboNoWarehouse",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {
    label: {
      type: String,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    forRecord: {
      type: Boolean,
      default: () => false,
    },
    forProduction: {
      type: Boolean,
      default: () => false,
    },
    selectItems: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    value: null,
    dropdown: false,
    newItem: {},
    suggestions: [],
    page: 1,
    totalPage: 1,
  }),
  computed: {
    selectedIds() {
      return [...this.selectItems].map((s) => s.id);
    },
  },
  watch: {
    page() {
      this.getSuggestion();
    },
    value() {
      this.page = 1;
    },
  },
  mounted() {},
  methods: {
    onChange() {},
    getSuggestion: debounce(function () {
      httpClient
        .post("suggest-combo-list", {
          keywords: this.value,
          page: this.page,
          limit: 5,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.suggestions = [...data.rows];
        });
    }, 500),
    selectItem(item) {
      this.$emit("onSelect", item);
    },
    showDropdown() {
      this.dropdown = true;
    },
    hideDropdown() {
      this.dropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.result-list {
  width: 100%;
  background: #fff;
  z-index: 1;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  max-height: 300px;
  overflow-y: auto;
  border-radius: 0 0 5px 5px;

  .result-item {
    padding: 8px;
    border-bottom: 1px solid #f3f3f3;
    font-size: 12px;

    &:hover {
      background: #efefef;
    }

    &.selected-item {
      background-color: #eff6fd;
      color: #000000;
      font-weight: 600;
    }
  }
}
</style>
